<template>
  <div class="e-dialog">
    <router-link :to="{ name: 'chatDialogs' }" class="e-dialog__back">
      <IconChevronLeft />
    </router-link>
    <div class="e-dialog__inner">
      <div class="e-dialog__scroll">
        <read-chat
          :prop-id="propId"
          :prop-messages="propMessages"
          :opponent="opponent"
        />
      </div>
    </div>
  </div>
</template>

<script>
import ReadChat from './ReadChat/ReadChat'
import IconChevronLeft from 'assets/icons/chevron-left.svg'

export default {
  components: {
    ReadChat,
    IconChevronLeft
  },

  props: {
    propMessages: {
      type: Array,
      default: () => [],
      required: false
    },
    propId: {
      type: String,
      default: null,
      required: false
    },
    opponent: {
      type: String,
      default: null,
      required: false
    }
  }
}
</script>

<style lang="scss" scoped>
@import '_assets/ChatDialogWrapper';
</style>
